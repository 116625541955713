import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/App/Home/HomeView"),
  },
    {
    path: "/home",
    name: "Home View",
    component: () => import("../views/App/Home/HomeView"),
  },
  {
    path: "/uc",
    name: "Under Construction",
    component: () => import("../views/App/Home/UnderConstruction"),
  },
  {
    path: "/test",
    name: "test page",
    component: () => import("../views/App/Home/TestView"),
  },

  {
    path: "/nopermission",
    name: "No Permission",
    component: () => import("../views/App/Home/PermissionError"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Auth/LoginView"),
  },

  {
    path: "/users",
    name: "Users",
    component: () => import("../views/App/Users/UsersView"),
  },
  {
    path: "/users_logins",
    name: "Users Logins",
    component: () => import("../views/App/Users/UsersLoginsView"),
  },
  {
    path: "/users_profile",
    name: "My Profile",
    component: () => import("../views/App/Users/UserProfile"),
  },

  {
    path: "/categories",
    name: "Categories",
    component: () => import("../views/App/Inventory/CategoriesView.vue"),
  },
  {
    path: "/products",
    name: "Products",
    component: () => import("../views/App/Inventory/ProductsView.vue"),
  },
  {
    path: "/material_recipes",
    name: "Recipes ",
    component: () => import("../views/App/Inventory_issue/MaterialRecipes.vue"),
  },
  {
    path: "/purchase_requisition",
    name: "Purchase Requisition ",
    component: () => import("../views/App/Purchase/PurchaseRequisition.vue"),
  },
    {
    path: "/purchase_requisition_ov",
    name: "Purchase Requisitions",
    component: () => import("../views/App/Purchase/PurchaseRequisitionOv.vue"),
  },
  {
    path: "/good_receive_form",
    name: "Good Receive",
    component: () => import("../views/App/Inventory_grn/GrnForm.vue"),
  },
  {
    path: "/good_receive_ov",
    name: "Good Receives",
    component: () => import("../views/App/Inventory_grn/GrnOv.vue"),
  },
  {
    path: "/finished_products_recieve",
    name: "Finished Products Receives",
    component: () => import("../views/App/Inventory_grn/FinishedProductsRecieve.vue"),
  },


{
    path: "/imcoming_maretial_inspection",
    name: "Incoming Meterial Inspection",
    component: () => import("../views/App/Inventory_grn/MaterialInspectionSheet.vue"),
  },
  {
    path: "/suppliers",
    name: "Suppliers",
    component: () => import("../views/App/Inventory_grn/SuppliersView.vue"),
  },
  {
    path: "/stock_balance",
    name: "Stock Balance",
    component: () => import("../views/App/Inventory/StockBalance.vue"),
  },
  {
    path: "/stock_balance_recipes",
    name: "Stock Balance Recipes",
    component: () => import("../views/App/Inventory/StockBalanceRecipes.vue"),
  },
  {
    path: "/stock_transfer",
    name: "Stock Transfer",
    component: () => import("../views/App/Inventory/StockTransfer.vue"),
  },
   {
    path: "/stock_entry",
    name: "Stock Entry",
    component: () => import("../views/App/Inventory/StockEntryView.vue"),
  },
  {
    path: "/issue_note_form",
    name: "Issue Note",
    component: () => import("../views/App/Inventory_issue/IssueForm.vue"),
  },
  {
    path: "/issue_note_ov",
    name: "Issue Notes",
    component: () => import("../views/App/Inventory_issue/IssueOv.vue"),
  },
  {
    path: "/return_note_form",
    name: "Return Note",
    component: () => import("../views/App/Inventory_issue/ReturnForm.vue"),
  },
  {
    path: "/return_note_ov",
    name: "Return Notes",
    component: () => import("../views/App/Inventory_issue/ReturnOv.vue"),
  },
  {
    path: "/personal_issue_note",
    name: "Personal Issue",
    component: () => import("../views/App/Inventory_issue/PeiRegister.vue"),
  },
   {
    path: "/issue_note_reports",
    name: "Issue Note Reports",
    component: () => import("../views/App/Inventory_issue/IssueNoteReports.vue"),
  },
  {
    path: "/employees_ov",
    name: "Employees",
    component: () => import("../views/App/Hr/EmployeesView.vue"),
  },
   {
    path: "/departments",
    name: "Departments",
    component: () => import("../views/App/Hr/DeptView.vue"),
  },
   {
    path: "/jobcard",
    name: "Job Card",
    component: () => import("../views/App/Production/JobCard.vue"),
  },
   {
    path: "/maintenance_sheets",
    name: "Maintenance",
    component: () => import("../views/App/Maintenance/MaintenanceSheetsView.vue"),
  },
   {
    path: "/maintenance_form",
    name: "Maintenance Form",
    component: () => import("../views/App/Maintenance/MaintananceForm.vue"),
  },
   {
    path: "/maintenance_sheets_report",
    name: "Maintenance Report",
    component: () => import("../views/App/Maintenance/MaintenanceSheetsReportView.vue"),
  },
   {
    path: "/service_orders_ov",
    name: "Service Orders List",
    component: () => import("../views/App/ServiceOrders/ServiceOrders"),
  },
   {
    path: "/customers_ov",
    name: "Customers View",
    component: () => import("../views/App/Customers/CustomersView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

import store from "@/store";
router.beforeEach((to, from, next) => {
  document.title = "UDAYA | " + to.name;
  if (to.name !== "Login" && !store.state.user) {
    next({ name: "Login" });
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});
export default router;
